import sbiLogo from "assets/images/sbiLogo.png";
import aubLogo from "assets/images/aubLogo.png";

export const handleLogo = (logo: string) => {
  switch (logo) {
    case "SBI_LOGO":
      return sbiLogo;
    case "AUB_LOGO":
      return aubLogo;
    default:
      return logo;
  }
};
